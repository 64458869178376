<template>
  <div class="home">
    <div class="header">
      <Header />
    </div>
    <div class="content">
      <router-view :key="key" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/header.vue";

export default {
  name: "Home",
  components: {
    Header,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100vh;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  position: relative;
  .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999999;
  }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.content {
  height: calc(100% - 120px);
  white-space: nowrap;
}
</style>
