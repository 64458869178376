<template>
  <div class="header">
    <div class="header-logo">
      <el-image :src="logo"></el-image>
    </div>
    <div class="header-nav">
      <el-menu
        :default-active="activeIndex"
        class="homeNav"
        mode="horizontal"
        @select="handleSelect"
        background-color="#242526"
        text-color="#fff"
        active-text-color="#CD171C"
      >
        <el-menu-item
          v-for="(item, i) in menu"
          :key="i + 'menu'"
          :index="i + ''"
        >
          {{ item.name }}
        </el-menu-item>
      </el-menu>
    </div>
    <!-- <div class="header-user">
      <div class="user-top">
        <span></span>
        <span></span>
      </div>
      <div class="user-bottom">
        <span>gaungshihuiyan</span>
        <span></span>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [
        //一级菜单数据
        { name: "首页", path: "/home" },
        { name: "联系我们", path: "/contact" },
      ],
      logo: require("@/assets/image/logo.png"),
    };
  },
  computed:{
    activeIndex() {
      let path = this.$route.path;
      let rs = this.menu.findIndex(item=>item.path == path) + '';
      return rs
    }
  },
  methods: {
    handleSelect(key) {
      this.$router.push(this.menu[key].path);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 120px;
  background: #242526;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .header-logo {
    width: 52px;
    height: 50px;
    margin-right: 137px;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
  .header-nav {
    .homeNav {
      border-bottom: none;
      .el-menu-item {
        font-size: 20px;
        margin-right: 130px;
      }
      .el-menu-item:hover,
      .el-menu-item:focus {
        background: transparent !important;
      }
      .is-active {
        background: transparent !important;
        border-bottom: 0px solid transparent !important;
      }
      .is-active::after {
        content: "";
        position: absolute;
        left: 40%;
        top: 80%;
        bottom: 0;
        right: 0;
        height: 3px;
        width: 18px;
        background-color: #cd171c;
      }
    }
  }
  .header-user {
    position: absolute;
    right: 0;
    top: 50px;
    // top: 25px;
    font-size: 18px;
    color: #dedede;
    .user-top {
      span {
        display: inline-block;
        height: 3px;
        background: #f6f6f6;
        opacity: 0.8;
        border-radius: 2px;
      }
      span:nth-child(1) {
        width: 40px;
        margin-right: 14px;
      }
      span:nth-child(2) {
        width: 186px;
      }
    }
    .user-bottom {
      display: flex;
      align-items: center;
      span:nth-child(2) {
        display: inline-block;
        width: 115px;
        height: 3px;
        background: #f6f6f6;
        opacity: 0.8;
        border-radius: 2px;
        margin-left: 6px;
      }
    }
  }
}
</style>